import { Observer } from 'mobx-react-lite';
import React from 'react';
import joinClassName from '../../utils/className.utils';
import './BaseButton.scss';

type BaseButtonProps = {
  className?: string,
  solid?: boolean,
  onClick?: () => void,
  title?: string,
}

const BaseButton: React.FC<BaseButtonProps> = props => {
  return <Observer children={() => (
    <button
      className={
        joinClassName(
          'BaseButton',
          props.className, 
          props.solid && 'solid'
        )
      }
      onClick={props.onClick}
      title={props.title}
    >
      {props.children}
    </button>
  )} />
}

export default BaseButton;