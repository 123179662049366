import { action, flow, toJS } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import { LOAD_GTAG } from '../../analytics/loadGTAG.analytics';
import { isBuildTime } from '../../env';
import { useOnMount } from '../../hooks/lifecycle.hooks';
import { useSyncObservableStateToStorage } from '../../hooks/useSyncObservableStateToStorage.hook';
import { useStore } from '../../utils/mobx.utils';
import { AnimatedLink } from '../AnimatedLink/AnimatedLink';
import BaseButton from '../BaseButton/BaseButton';
import Line from '../Line/Line';
import PanelToggle from '../PanelToggle/PanelToggle';
import './AnalyticsManager.scss';


type AnalyticsManagerProps = {}

export type GDPR_PREFERENCES = {
  enableEssentialCookies: boolean,
  enableGoogleAnalytics: boolean,
  hasReviewed: boolean,
}

const AnalyticsManager: React.FC<AnalyticsManagerProps> = props => {
  const s = useStore(() => ({
    get shouldShowBanner() {
      return !s.serialisable.hasReviewed;
    },
    serialisable: {
      enableEssentialCookies: true,
      enableGoogleAnalytics: true,
      hasReviewed: false,
    },
    showManagerOverlay: false,
    hasLoaded: {
      googleAnalytics: false,
    }
  }))
  useSyncObservableStateToStorage(
    'GDPR_PREFERENCES',
    () => toJS(s.serialisable),
    value => Object.assign(s.serialisable, value)
  )
  const manage = action(() => {
    s.serialisable.hasReviewed = true;
    s.showManagerOverlay = true;
  })
  const acceptAll = action(() => {
    s.serialisable.hasReviewed = true;
    s.serialisable.enableGoogleAnalytics = true;
    applyChanges();
  })
  const applyChanges = action(() => {
    s.showManagerOverlay = false;
    s.serialisable.hasReviewed = true;
    initAllEnabled();
  })
  const initAllEnabled = async () => {
    if (s.serialisable.enableGoogleAnalytics) await initGA();
  }
  const initGA = () => new Promise<void>(action((resolve) => { 
    if (s.hasLoaded.googleAnalytics) return;
    s.hasLoaded.googleAnalytics = true;
    // if (isDevelopment) {
    //   log('Google Analytics is not loaded as the app is in development mode. Tracking ID: ', process.env.GTAG_ID);
    //   return;
    // }
    /** It's using GTAG, but the global variable is curiously "ga". Whatever! */
    if (process.env.GTAG_ID) 
      // console.log('TODO: Uncomment below when correct GTAG is provided in .env file')
      LOAD_GTAG(process.env.GTAG_ID as string, resolve);
    else resolve();
  }))

  const origin = useQueryParam('o', StringParam)[0];

  useOnMount(() => {
    flow(function* () {
      if (isBuildTime) return;
      window.MANAGE_PRIVACY_SETTINGS = manage;
      s.serialisable.enableEssentialCookies = true;
      yield initAllEnabled();
      if (origin && window.dataLayer) {
        // this will run only once, as <AnalyticsManager> only gets loaded once in the global wrapping component. 
        window.dataLayer.push({ origin });
      }
    })()
  })
  
  return <Observer children={() => (
    <div className="AnalyticsManager">
      {s.shouldShowBanner && <section className="AnalyticsManagerBanner">
        <div className="AnalyticsManagerBannerContent">
          <h2>Oh hi there, we've got cookies!</h2>
          {/* <p>We use cookies and similar tech to analyse traffic and site usage. You can read about our <AnimatedLink to="/data-protection-policy" title="Data Protection Policy">Data Protection Policy</AnimatedLink>, or <PseudoLink onClick={manage}>click here</PseudoLink> to review settings.</p> */}
          <p>We use cookies and similar tech to analyse traffic and site usage. You can <AnimatedLink to="/data-protection-policy" title="Data Protection Policy">read about our Data Protection Policy here</AnimatedLink>.</p>
        </div>
        <BaseButton className="AnalyticsManagerBannerAcceptButton" onClick={acceptAll} solid>
          <div className="AnalyticsManagerBannerAcceptButtonCookieIcon">🍪</div>
          <div>Accept</div>
        </BaseButton>
      </section>}
      {
        s.showManagerOverlay && <div className="AnalyticsManagerOverlayContainer">
          <span className="AnalyticsManagerOverlayBackdrop"></span>
          <div className="AnalyticsManagerOverlay">
            <header>
              <h2>Privacy Settings</h2>
              <Line />
            </header>
            <div className="AnalyticsManagerOverlayBody">
              <p>Please manage your cookie choices and privacy settings listed below by switching the consent toggles on or off. You can also choose to:</p>
              <PanelToggle
                id="essential-cookies-toggle"
                getter={() => s.serialisable.enableEssentialCookies}
              >
                <h3>Essential Cookies</h3>
                <p>Some cookies and similar technologies are used to run the website and application properly and cannot be turned off.</p>
              </PanelToggle>
              <PanelToggle
                id="google-analytics-toggle"
                getter={() => s.serialisable.enableGoogleAnalytics}
                setter={v => s.serialisable.enableGoogleAnalytics = v}
              >
                <h3>Google Analytics</h3>
                <p>Collects site traffic and usage information. This provides insight to where our visitors are coming from, and how everyone uses our services. You can know more about how Google Analytics and Google Tag Manager manages cookies and user/device information on their website.</p>
              </PanelToggle>
              <p><a href="https://marketingplatform.google.com/about/analytics/" target="_blank" rel="noreferrer">Know more about Google Analytics ↗</a></p>
            </div>
            <footer>
              <Line />
              <div className="AnalyticsManagerOverlayFooterButtonGroup">
                <BaseButton onClick={applyChanges}>Confirm Your Preferences</BaseButton>
                <BaseButton onClick={acceptAll}>Accept All</BaseButton>
              </div>
            </footer>
          </div>
        </div>
      }
    </div>
  )} />
}

export default AnalyticsManager;