import React, { CSSProperties } from 'react';

const style: CSSProperties = {
  position: 'fixed',
  top: -24,
  left: -24,
  opacity: 0,
}

const Symbols: React.FC = () => {
  return <div className="Symbols">
    <svg width="24" height="24" viewBox="0 0 24 24" style={style} xmlns="http://www.w3.org/2000/svg">
      <svg id="logo-hse" width="64" height="49" viewBox="0 0 64 49">
        <path d="M36.5 26.4C36.5 26.4 39.7 21.3 47.7 22.3C55.7 23.3 58.7 21 58.7 21C58.7 21 53.2 28.7 46.9 26.6C40.5 24.6 36.5 26.4 36.5 26.4Z" fill="#84A8A1" />
        <path d="M31.4 44.5001C31.4 44.5001 34.6 39.4001 42.6 40.4001C50.6 41.4001 53.6 39.1001 53.6 39.1001C53.6 39.1001 48.1 46.8001 41.8 44.7001C35.4 42.6001 31.4 44.5001 31.4 44.5001Z" fill="#84A8A1" />
        <path d="M41.6 9.4C41.6 9.4 44.8 4.3 52.8 5.3C60.8 6.3 63.8 4 63.8 4C63.8 4 58.4 11.7 52 9.6C45.7 7.5 41.6 9.4 41.6 9.4Z" fill="#84A8A1" />
        <path d="M26 21.8C26 21.8 25.4 22.4 22.5 22.4H14.5C13.2 22.4 13 22.9 13 22.9C13.1 22.6 13.2 21.7 13.3 21.4C14.9 12.7 16.1 5.90005 16.2 5.60005C16.7 3.70005 18.3 3.30005 18.3 3.30005H10.6C10.6 3.30005 9 3.30005 8.5 5.60005C8.4 6.30005 3 36.1001 1.9 42.7001C1.6 44.3001 0 44.8 0 44.8H7.6C7.6 44.8 9.1 44.7001 9.5 42.7001C10 40.1001 11 34.3 12.3 27.3C12.5 26.3 13.5 26.4 13.5 26.4H21.1C21.1 26.4 23.6 26.4001 24.8 25.2001C25.9 23.8001 26 21.8 26 21.8Z" fill="#00594E" />
        <path d="M27.7001 22.8999C26.2001 52.4999 4.80005 47.9999 4.80005 47.9999V48.1999C8.10005 48.9999 33 52.8999 34.6 25.9999C36.1 -3.60011 57.5 0.799895 57.5 0.799895V0.599895C54.2 -0.100105 29.3001 -4.0001 27.7001 22.8999Z" fill="#A32035" />
      </svg>

      <svg id="icon-hamburger" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <line x1="3" x2="21" y1="4" y2="4" stroke="#000" strokeWidth="2"/>
        <line x1="3" x2="21" y1="12" y2="12" stroke="#000" strokeWidth="2"/>
        <line x1="3" x2="21" y1="20" y2="20" stroke="#000" strokeWidth="2"/>
      </svg>

      <svg id="icon-check" width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.23896 1.14522L3.85524 6.52894L0.817452 3.49116" stroke="#0E51D3" strokeWidth="2"/>
      </svg>

    </svg>


  </div>
}

export default Symbols;