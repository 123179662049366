import { action } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import joinClassName from '../../utils/className.utils';
import { useProps, useStore } from '../../utils/mobx.utils';
import './PanelToggle.scss';

type PanelToggleProps = {
  id: string,
  className?: string,
  getter: () => boolean,
  setter?: (v: boolean) => void,
  onChange?: (v: boolean) => void,
  disabled?: boolean,
}

const PanelToggle = (props: React.PropsWithChildren<PanelToggleProps>) => {
  const p = useProps(props);
  const s = useStore(() => ({
    get value() {
      return p.getter();
    },
    set value(v) {
      p.setter?.(v);
    },
    get disabled() {  
      return p.disabled || !p.setter;
    },
    handleInputValueChange: action((e: React.ChangeEvent<HTMLInputElement>) => {
      s.value = e.currentTarget.checked;
      p.onChange && p.onChange(s.value);
    })
  }))
  return <Observer children={() => (
    <label className={
      joinClassName(
        'PanelToggle', 
        props.className,
        s.disabled && 'disabled',
        s.value ? 'on' : 'off'
      )
    } htmlFor={p.id}>
      <div className="PanelToggleInner">
        {props.children}
      </div>
      <div className="PanelToggleInputWrapper">
        <input
          type="checkbox" 
          checked={s.value}
          onChange={s.handleInputValueChange}
          id={p.id}
          name={p.id}
          disabled={s.disabled}
        />
        <span className="PanelToggleToggle">
          <span className="PanelToggleToggleKnob"></span>
        </span>
      </div>
    </label>
  )} />
}

export default PanelToggle;