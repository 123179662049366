import { action, flow, observable } from "mobx";
import React, { ReactNode } from 'react';
import { isLegacyEdge } from "react-device-detect";
import DialogLayer from "../components/DialogLayer/DialogLayer";
import { isBrowser } from "../env";
import { ActionConfig, DefaultActionResolveValue, DialogConfig } from "../types/app.types";
import { addToArrayIfNew, removeFromArray } from "../utils/array.utils";
import { isFirefox, isIE } from "../utils/browsers.utils";
import { defaultDialogActionsMap } from "../utils/dialogs.utils";
import { addRootClass, getScrollY } from "../utils/dom.utils";
import { initParallax } from "../utils/parallax.utils";
import { highPerf } from "../utils/performance.utils";
import PointerDetector from "../utils/pointerDetector.utils";
import { getRandomNumericString } from "../utils/random.utils";
import { isString } from "../utils/typeChecks.utils";
import { runAfter } from "../utils/waiters.utils";
import { AppController } from "./app.controller";

export const makeUIController = () => {
  let initHasCalled = false;
  const c = observable({
    ready: false,
    root: null as unknown as AppController,
    cssFeatures: {
      // grid: false,
      grid: isBrowser ? window.CSS?.supports('display: grid') && !isIE : true,
      customProperties: isBrowser ? window.CSS?.supports('--a: 1') && !isIE : true,
    },
    viewport: {
      width: isBrowser ? document.body.clientWidth : 768,
      height: isBrowser ? window.innerHeight : 768,
      scrollY: isBrowser ? getScrollY() : 0,
    },
    document: {
      width: isBrowser ? document.documentElement.scrollWidth : 768,
      height: isBrowser ? document.documentElement.scrollHeight : 768,
    },
    ui: {
      isDarkTheme: true,
      scrollbarWidth: isBrowser ? window.innerWidth - document.body.clientWidth : 0,
      pointerTypes: [] as string[],
    },
    cursor: {
      x: 0,
      y: 0,
    },
    isFirstLoad: true,
    dialogs: observable.array([], { deep: false }) as ReactNode[],
    presentDialog: <T extends DefaultActionResolveValue = DefaultActionResolveValue>(
      config: DialogConfig<T>
    ) => new Promise<T>(resolve => {
      const d = observable({
        resolveValue: undefined as T | undefined,
      });
      const handleActionTrigger = flow(function* (action: ActionConfig<T>) {
        yield action.beforeResolve?.();
        d.resolveValue = action.resolve;
        resolve(d.resolveValue);
        dispose();
      });
      const dialog = <DialogLayer
        key={getRandomNumericString()}
        config={config}
        onActionTrigger={handleActionTrigger} 
      />;
      addToArrayIfNew(c.dialogs, dialog);
      const dispose = () => {
        if (d.resolveValue === undefined) d.resolveValue = false as T;
        removeFromArray(c.dialogs, dialog);
      }
      return observable({
        get resolveValue() { return d.resolveValue },
        get resolved() { return d.resolveValue !== undefined },
        dispose,
      })
    }),
    prompt: <T extends DefaultActionResolveValue = DefaultActionResolveValue>(
      config: string | Omit<DialogConfig<T>, 'actions'>
    ) => {
      const actions = [defaultDialogActionsMap.true];
      if (isString(config)) return c.presentDialog({ Heading: config, actions });
      else return c.presentDialog({ ...config, actions });
    },
    init: action((root: AppController) => {
      if (initHasCalled) {
        console.warn('initiating the same UI Controller instance twice. aborting.');
        return;
      }
      c.root = root;
      if (isBrowser) {
        if (!c.cssFeatures.grid) addRootClass('nogrid');
        if (!c.cssFeatures.customProperties) {
          addRootClass('nocssvar dark');
        }

        
        // const initMobileMenu = () => {

        //   const handleMobileMenuToggle = () => {
        //     document.documentElement.classList.toggle('mobile-menu-open');
        //     console.warn('click');
        //   }
        //   const navMenuToggle = document.querySelector('.NavBarHamburger');
        //   if (navMenuToggle) {
        //     console.log('element found');
        //     console.log(navMenuToggle); 
        //     navMenuToggle.addEventListener('click', handleMobileMenuToggle);
        //   }
        //   // toggleMobileMenu();
        // }

        // initMobileMenu();

        document.documentElement.classList.toggle('low-perf', !highPerf);
        let lastScrollY = getScrollY();
        const checkScrollY = action(() => {
          c.viewport.scrollY = getScrollY();
          // document.documentElement.classList.toggle('page-scrolled', window.scrollY > 100);
          // document.documentElement.classList.toggle('page-scrolled', window.scrollY > 25);
          document.documentElement.classList.toggle('page-scrolled', window.scrollY > 0);
          document.documentElement.classList.toggle('reverse-scroll', c.viewport.scrollY < lastScrollY);
          lastScrollY = c.viewport.scrollY <= 0 ? 0 : c.viewport.scrollY;
        })
        const checkScrollbarWidth = action(() => {
          c.ui.scrollbarWidth = window.innerWidth - document.body.clientWidth;
        })
        const handleWindowScroll = () => {
          checkScrollY();
        }
        const handleWindowResize = action(() => {
          c.viewport.width = document.body.clientWidth;
          c.viewport.height = window.innerHeight;
          c.document.width = document.documentElement.scrollWidth;
          c.document.height = document.documentElement.scrollHeight;
          document.documentElement.style.setProperty('--vw', c.viewport.width + 'px');
          document.documentElement.style.setProperty('--vh', c.viewport.height + 'px');
          checkScrollY();
          checkScrollbarWidth();
        })
        const handleWindowFocus = () => {
          checkScrollbarWidth();
        }
        if (!isIE && !isFirefox) window.addEventListener('scroll', handleWindowScroll);
        window.addEventListener('resize', handleWindowResize);
        window.addEventListener('focus', handleWindowFocus);
        handleWindowResize();
        runAfter(handleWindowResize, 200); // check size briefly after page load, the scrollbar might have appeared
        runAfter(handleWindowResize, 1000); // check again in case the device is slow
        runAfter(handleWindowResize, 2000); // check again in case the device is terribly slow
        const initPointerDetector = () => {
          new PointerDetector({
            onDetectingMouse: action(() => c.ui.pointerTypes.push('mouse')),
            onDetectingTouch: action(() => c.ui.pointerTypes.push('touch')),
            onDetectingStylus: action(() => c.ui.pointerTypes.push('stylus')),
          });
        }
        
        initPointerDetector();
        initParallax();
        runAfter(action(() => {
          c.isFirstLoad = false;
        }), 1000);
        runAfter(() => {
          if (isIE) {
            c.prompt({
              Heading: 'You are using Internet Explorer and might not see the page rendered as intended.',
              Body: 'We recommend visiting our website with newer browsers.',
            })
          } else if (isLegacyEdge) {
            c.prompt({
              Heading: 'You are using a legacy version of Microsoft Edge and might not see the page rendered as intended.',
              Body: 'We recommend visiting our website with the latest version of Microsoft Edge, or other newer browsers.',
            })
          }
        }, 4000);
        c.ready = true;
      }
    }),
  })
  return c;
}
export type UIController = ReturnType<typeof makeUIController>;