import { Observer } from 'mobx-react-lite';
import React from 'react';
import { ActionConfig, DefaultActionResolveValue, DialogConfig } from '../../types/app.types';
import { defaultDialogActionsArray } from '../../utils/dialogs.utils';
import { useProps, useStore } from '../../utils/mobx.utils';
import { isString } from '../../utils/typeChecks.utils';
import BaseButton from '../BaseButton/BaseButton';
import './DialogLayer.scss';

type DialogLayerProps<T> = {
  config: DialogConfig<T>,
  onActionTrigger: (action: ActionConfig<T>) => void;
}

const DialogLayer = <T extends DefaultActionResolveValue = DefaultActionResolveValue>(props: React.PropsWithChildren<DialogLayerProps<T>>) => {
  const p = useProps(props);
  const s = useStore(() => ({
    dismiss: () => p.onActionTrigger({resolve: false as T}),
  }))
  return <Observer children={() => (
    <section className="DialogLayer">
      <span className="DialogBackdrop" onClick={s.dismiss} />
      <div className="Dialog">
        <header className="DialogHeader">
          <div className="DialogHeaderContent">
            {isString(p.config.Heading) ? <h3>{p.config.Heading}</h3> : p.config.Heading}
          </div>
          <button className="DialogCloseButton" onClick={s.dismiss}>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"><path d="M15 1L1 15M1 1L15 15" stroke="currentColor" /></svg>
          </button>
        </header>
        {p.config.Body && <div className="DialogBody">
          {isString(p.config.Body) ? <p>{p.config.Body}</p> : p.config.Body}
        </div>}
        <footer className="DialogFooter">
          {(p.config.actions || defaultDialogActionsArray).map((action, i) => <BaseButton
            key={action.title ?? i}
            title={action.title}
            children={action.Label || (action.resolve + '')}
            onClick={() => p.onActionTrigger(action as ActionConfig<T>)}
          />)}
        </footer>
      </div>
    </section>
  )} />
}

export default DialogLayer;