import { observable } from "mobx";
import { ReactNode } from "react";
import ReactDOM from "react-dom";
import { isBrowser } from "../env";
import { AppController } from "./app.controller";

export const makePortalController = () => {

  const c = observable({
    root: null as unknown as AppController,
    el: isBrowser ? document.getElementById('app-portal') : null,
    render: (renderable: ReactNode) => {
      if (!c.el) {
        return null;
      }
      return ReactDOM.createPortal(
        renderable,
        c.el,
      )
    },
    init: (root: AppController) => {
      c.root = root;
    }
  })

  return c;

}

export type PortalController = ReturnType<typeof makePortalController>;
