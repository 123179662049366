module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Trackster","short_name":"Trackster","start_url":"/","description":"Trackster: Intuitive Time-tracking + Kanbans","background_color":"#ffffff","theme_color":"#0D5FFF","display":"minimal-ui","lang":"en","icon":"src/favicon/@icons/icon.png","icons":[{"src":"src/favicon/favicon-16x16.png","type":"image/png","sizes":"16x16"},{"src":"src/favicon/favicon-32x32.png","type":"image/png","sizes":"32x32"},{"src":"src/favicon/@icons/icon.png","type":"image/png","sizes":"512x512"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1863b601110cbce980f7a715031dcaed"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx"],"defaultLayouts":{},"gatsbyRemarkPlugins":[],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/Users/bryansng/Documents/repos/trackster-gatsby-and-app/trackster-gatsby"},
    },{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-use-query-params/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"typekit":{"id":"xxxxxxx"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-ts-config/gatsby-browser.js'),
      options: {"plugins":[],"configDir":"gatsby","projectRoot":"/Users/bryansng/Documents/repos/trackster-gatsby-and-app/trackster-gatsby"},
    }]
