require('intersection-observer');
import { action, extendObservable, observable } from "mobx";
import { isBrowser, isBuildTime } from "../env";
import { ObservableRef } from "../hooks/useObservableRef.hook";
import { isIE } from "./browsers.utils";
import { NoOp } from "./functions.utils";
import { hasKey } from "./object.utils";
import { highPerf } from "./performance.utils";

type VisibilityObserverRegistryItem = {
  /** the ID on the element, must be supplied in order for observer to find the callbacks */
  id: string,
  visibleClass?: string,
  invisibleClass?: string,
  onBecomeVisible?: () => void,
  onBecomeInvisible?: () => void,
  once?: boolean,
}
const callbackRegistry = observable({}) as Record<string, VisibilityObserverRegistryItem>;
const animationObserver = (isBrowser && !isIE) ? new IntersectionObserver((entries, observer) => {
  for (const entry of entries) {
    const id = entry.target.getAttribute('id');
    if (id) {
      const def = callbackRegistry[id];
      if (!def) return;
      if (entry.isIntersecting) {
        def.onBecomeVisible?.();
        if (def.once) {
          animationObserver!.unobserve(entry.target);
          callbackRegistry[id] = { id };
        }
      }
      else def.onBecomeInvisible?.();
    }
    entry.target.classList.toggle('visible', entry.isIntersecting)
    entry.target.classList.toggle('invisible', !entry.isIntersecting)
  }
}, { rootMargin: '-100px' }) : null;

export const observeVisibility = action((
  ref: ObservableRef<HTMLElement | SVGElement> | HTMLElement | SVGElement,
  options: VisibilityObserverRegistryItem,
) => {
  if (isBuildTime || !animationObserver) return NoOp;
  if (!highPerf || isIE) {
    options.onBecomeVisible?.();
    return NoOp;
  }
  const element = ref instanceof Element ? ref : ref.current!;
  animationObserver.observe(element);
  if (hasKey(callbackRegistry, options.id)) {
    callbackRegistry[options.id] = options;
  } else {
    extendObservable(callbackRegistry, {
      [options.id]: options,
    });
  }
  return action(() => {
    callbackRegistry[options.id] = { id: options.id };
    animationObserver.unobserve(element);
  })
})