import { useInitManualHydrate } from "./utils/hydrate.utils";

export const isBrowser = typeof window !== "undefined";
export const isBuildTime = !isBrowser;

export const isProduction = process.env.NODE_ENV === 'production';
export const isDevelopment = process.env.NODE_ENV === 'development';

export const isInCypressTestMode = isBrowser && Boolean(window.Cypress);

const LocalAPIOverrideStorageKey = 'LOCAL_API_OVERRIDE';

const hostname = isBrowser ? window.location.hostname : '';

export const isLocalhost = hostname === 'localhost';

export const REACT_APP_HOSTNAME = (function () {
  const DEV_REACT_APP_PORT = "1280";
  switch (hostname) {
    case 'localhost':
    case 'trackster-app.test':
      return `localhost:${DEV_REACT_APP_PORT}`;
    case 'app.trackster.io':
    case 'trackster.io':
    case 'www.trackster.io':
    default:
      return 'www.trackster.io';
  }
})()
export const REACT_APP_ORIGIN = `https://${REACT_APP_HOSTNAME}`;

export const useGetReactAppOrigin = () => useInitManualHydrate({ getter: () => REACT_APP_ORIGIN });