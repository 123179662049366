import React, { CSSProperties } from 'react';
import joinClassName from '../../utils/className.utils';
import './Line.scss';

type LineProps = {
  className?: string,
  width?: number | string,
  animate?: boolean,
  animationDelay?: CSSProperties['animationDelay'],
}

const Line: React.FC<LineProps> = props => {
  return <span
    className={joinClassName('Line', props.animate && 'animate', props.className)}
    style={{ width: props.width, animationDelay: props.animationDelay }}
  />
}

export default Line;