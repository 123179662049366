// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-blog-mdx-slug-tsx": () => import("./../../../src/pages/blog/{mdx.slug}.tsx" /* webpackChunkName: "component---src-pages-blog-mdx-slug-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-data-protection-policy-tsx": () => import("./../../../src/pages/data-protection-policy.tsx" /* webpackChunkName: "component---src-pages-data-protection-policy-tsx" */),
  "component---src-pages-features-index-tsx": () => import("./../../../src/pages/features/index.tsx" /* webpackChunkName: "component---src-pages-features-index-tsx" */),
  "component---src-pages-features-insights-tsx": () => import("./../../../src/pages/features/insights.tsx" /* webpackChunkName: "component---src-pages-features-insights-tsx" */),
  "component---src-pages-features-insights-x-tsx": () => import("./../../../src/pages/features/insights-x.tsx" /* webpackChunkName: "component---src-pages-features-insights-x-tsx" */),
  "component---src-pages-features-projects-tsx": () => import("./../../../src/pages/features/projects.tsx" /* webpackChunkName: "component---src-pages-features-projects-tsx" */),
  "component---src-pages-features-time-tracking-tsx": () => import("./../../../src/pages/features/time-tracking.tsx" /* webpackChunkName: "component---src-pages-features-time-tracking-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-thank-you-tsx": () => import("./../../../src/pages/thank-you.tsx" /* webpackChunkName: "component---src-pages-thank-you-tsx" */)
}

