import { ActionConfig, DefaultActionResolveValue } from "../types/app.types";

export const defaultDialogActionsMap: Record<'true' | 'false', ActionConfig<DefaultActionResolveValue>> = {
  true: {
    resolve: true,
    Label: 'OK',
  },
  false: {
    resolve: false,
    Label: 'Cancel',
  },
}
export const defaultDialogActionsArray = [
  defaultDialogActionsMap.true,
  defaultDialogActionsMap.false,
]