import { uniq } from "./ramdaEquivalents.utils";

export const addToArrayIfNew = <T = any>(arr?: T[], ...itemsToAdd: T[]) => {
  if (!arr) return arr;
  for (let item of itemsToAdd) {
    if (arr.includes(item)) continue;
    arr.push(item);
  }
  return arr;
}

export const removeFromArray = <T = any>(arr?: T[], ...itemsToRemove: T[]) => {
  if (!arr) return arr;
  for (let item of itemsToRemove) {
    let i = arr.indexOf(item);
    while (i >= 0) {
      arr.splice(i, 1);
      i = arr.indexOf(item);
    }
  }
  return arr;
}

export const toggleInArrayWithPredicate = <T = any>(arr?: T[], predicate?: any, ...itemsToToggle: T[]) => {
  if (!arr) return;
  if (Boolean(predicate)) addToArrayIfNew(arr, ...itemsToToggle);
  else removeFromArray(arr, ...itemsToToggle);
}

export const intersection = <A, B>(arrA: A[], arrB: B[]) => uniq(arrB.filter(b => arrA.includes(b as any))) as (A | B)[];
export const hasIntersection = <A, B>(arrA: A[], arrB: B[]) => intersection(arrA, arrB).length > 0;

export const sortRandomly = <T>(arr: T[]) => Array.from(arr).sort((a, b) => .5 - Math.random());
