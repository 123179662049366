import { observable } from "mobx";
import { createContext, useContext } from "react";
import packageJson from '../../package.json';
import { isBrowser, isDevelopment } from "../env";
import { CLOCK, ClockController } from "./clock.controller";
import { makePortalController, PortalController } from "./portal.controller";
import { makeUIController, UIController } from "./ui.controller";

const makeSiteInfo = () => ({
  name: 'Trackster',
  version: packageJson.version,
})

export type AppController = {
  siteInfo: ReturnType<typeof makeSiteInfo>,
  PORTAL: PortalController,
  UI: UIController,
  CLOCK: ClockController,
}

const makeAppController = () => {
  const c: AppController = observable({
    siteInfo: makeSiteInfo(),
    PORTAL: makePortalController(),
    UI: makeUIController(),
    CLOCK,
  })
  c.PORTAL.init(c);
  c.UI.init(c);
  if (isDevelopment && isBrowser) {
    Reflect.set(window, 'ROOT', c);
    Reflect.set(window, 'UI', c.UI);
    Reflect.set(window, 'CLOCK', c.CLOCK);
  }
  if (isBrowser) {
    document.documentElement.setAttribute('data-version', c.siteInfo.version);
  }
  return c;
}

export const AppController = makeAppController();

export const AppContext = createContext(AppController);

export const useAppContext = () => useContext(AppContext);