import { Observer } from 'mobx-react-lite';
import React from 'react';
import { useAppContext } from '../../controllers/app.controller';
import './DialogStack.scss';

type DialogStackProps = {}

const DialogStack: React.FC<DialogStackProps> = props => {
  const { PORTAL, UI } = useAppContext();
  return <Observer children={() => (
    UI.dialogs.length > 0 ? PORTAL.render(<div className="DialogStack">
      { UI.dialogs }
    </div>) : null
  )} />
}

export default DialogStack;