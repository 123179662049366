import { action, reaction } from "mobx";
import { useOnMount } from "../hooks/lifecycle.hooks";
import { useStore } from "./mobx.utils";

// There is an initial discrepancy between server and client rendered data.
// On hard refreshes or on initial fetch of data from server, the data will be static data precalculated by server.
// On subsequent refreshes, the client side rendering will be used.
//
// On hard refreshes or initial fetch, client side rendering will not rerender explicitly.
// This hooked is used to help facilitate the forcing of client side rendering.
//
// Usage:
// - Add it to an element's key attribute to help React perform diff Reconciliation. Make sure the key attribute is unique relative to other key attributes within a component. If used by many elements within the same component, add to their parent element's key attribute.
// - Add fallback using ??, null coalescing operator, so server side rendering has a default value to use, instead of null.
export const useInitManualHydrate = <T>(args: {
  getter: () => T;
}): T | null => {
  const s = useStore(() => ({
    state: null as T | null,
  }))
  useOnMount(() => {
    reaction(
      () => args.getter(),
      action(() => {
        s.state = args.getter();
      }),
      { fireImmediately: true }
    )
  })
  return s.state;
}