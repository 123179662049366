import { Link } from "gatsby";
import { TweenLite } from 'gsap';
import React, { ReactNode } from 'react';
import { useGetDataAttributes } from "../../hooks/useGetDataAttributes.hook";
import { TransitionTrigger } from "../../types/transitions.types";
import joinClassName from "../../utils/className.utils";
import { getScrollY } from "../../utils/dom.utils";
import { highPerf } from "../../utils/performance.utils";
import { AlwaysFalseFn } from "../../utils/ramdaEquivalents.utils";

const AnimatedLinkTrigger: (...args: any[]) => TransitionTrigger = (type: 'entry' | 'exit') => args => {
  // log(args.exit);
  // log(args.node);
  const duration = highPerf ? (getScrollY() > window.innerHeight * .62 ? 0 : args.exit?.length ?? args.entry?.length ?? .2) : 0;
  if (duration === 0) {
    if (type === 'exit') TweenLite.set(args.node, { opacity: 0 });
    return;
  }

  // const from = {
  //   opacity: 0,
  //   ease: Power2.easeInOut,
  // }
  // const to = {
  //   opacity: 1,
  //   ease: Power2.easeInOut,
  // }

  // if (type === 'entry') TweenLite.fromTo(args.node, duration, from, to);
  // else if (type === 'exit') TweenLite.fromTo(args.node, duration / 2, to, from);
}

interface AnimatedLinkProps {
  className?: string,
  to: string,
  title: string,
  length?: number,
  onClick?: () => void,
  activeClassChecker?: () => boolean,
  target?: string,
  rel?: string,
  children?: ReactNode
}

/**
 * NOTE: Due to it also animating the NavBar, this is reverted back to a Link that doesn't animate.
 *
 * A wrapper around the TransitionLink component, provides basic fading effect for entering and exiting pages.
 */
export const AnimatedLink = React.forwardRef((props: React.PropsWithChildren<AnimatedLinkProps>, ref) => {
  const dataAttr = useGetDataAttributes(props);
  const commonAttr = {
    className: joinClassName(props.className, (props.activeClassChecker ?? AlwaysFalseFn)() ? 'active' : undefined),
    to: props.to,
    title: props.title,
    length: props.length,
    children: props.children,
    onClick: props.onClick,
    activeClassName: "active",
    target: props.target,
    rel: props.rel,
    ...dataAttr,
  }
  // if (enableHighPerfAnim) {
    // return <TransitionLink
    //   {...commonAttr}
    //   ref={ref}
    //   exit={{
    //     length: highPerf ? (props.length ?? .38) : 0,
    //     trigger: AnimatedLinkTrigger('exit')
    //   }}
    //   entry={{
    //     length: highPerf ? (props.length ?? .38) : 0,
    //     trigger: AnimatedLinkTrigger('entry')
    //   }}
    // />
  // } else {
  return <Link {...commonAttr} />
  // }
})